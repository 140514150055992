// Import Supported Fonts for Text Canvas Element
// @import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap"); // Dancing Script
// @import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); // Fira Sans
// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..800&display=swap"); // Inter
// @import url("https://fonts.googleapis.com/css2?family=Inconsolata:wdth,wght@50..200,200..900&display=swap"); // Inconsolata
// @import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap"); // JetBrains Mono
// @import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap"); // Merriweather
// @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap"); // Oswald
// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); // Poppins
// @import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap"); //Playfair
// @import url("https://fonts.googleapis.com/css2?family=Recursive:wght@300..1000&display=swap"); // Recursive
// @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); // Roboto
// @import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap"); // Rubik
// @import url("https://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap"); // Rethink Sans sjdf

html,
body {
  font-family: "Aller", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

/* DancingScript */
@font-face {
  font-family: 'Dancing Script';
  src: url('./assets/fonts/DancingScript-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900; /* Adjust the range based on the actual weights */
  font-style: normal;
}

/* FiraSans */
@font-face {
  font-family: 'Fira Sans';
  src: url('./assets/fonts/FiraSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Inconsolata */
@font-face {
  font-family: 'Inconsolata';
  src: url('./assets/fonts/Inconsolata-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 100 900; /* Adjust the range based on the actual weights */
  font-stretch: 75% 100%; /* Adjust if needed */
  font-style: normal;
}

/* Inter */
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-VariableFont_opsz,wght.ttf') format('truetype');
  font-weight: 100 900; /* Adjust the range based on the actual weights */
  font-optical-sizing: auto;
  font-style: normal;
}

/* JetBrains Mono */
@font-face {
  font-family: 'JetBrainsMono';
  src: url('./assets/fonts/JetBrainsMono-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900; /* Adjust the range based on the actual weights */
  font-style: normal;
}

/* Merriweather */
@font-face {
  font-family: 'Merriweather';
  src: url('./assets/fonts/Merriweather-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Oswald */
@font-face {
  font-family: 'Oswald';
  src: url('./assets/fonts/Oswald-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900; /* Adjust the range based on the actual weights */
  font-style: normal;
}

/* Playfair */
@font-face {
  font-family: 'Playfair';
  src: url('./assets/fonts/Playfair-VariableFont_opsz,wdth,wght.ttf') format('truetype');
  font-weight: 100 900; /* Adjust the range based on the actual weights */
  font-stretch: 75% 100%; /* Adjust if needed */
  font-optical-sizing: auto;
  font-style: normal;
}

/* Poppins */
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500; /* Adjust if you have multiple weights */
  font-style: normal;
}

/* Recursive */
@font-face {
  font-family: 'Recursive';
  src: url('./assets/fonts/Recursive-VariableFont_CASL,CRSV,MONO,slnt,wght.ttf') format('truetype');
  font-weight: 100 900; /* Adjust the range based on the actual weights */
  font-style: normal;
  font-variation-settings: "CASL" 0, "CRSV" 0, "MONO" 0, "slnt" 0, "wght" 400;
}

/* RethinkSans */
@font-face {
  font-family: 'RethinkSans';
  src: url('./assets/fonts/RethinkSans-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900; /* Adjust the range based on the actual weights */
  font-style: normal;
}

/* Roboto */
@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Rubik */
@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900; /* Adjust the range based on the actual weights */
  font-style: normal;
}

.authpage-inner-wrapper {
  .create-btn {
    button {
      width: 100%;
    }
  }
}

// canvas classes
.canvas-board {
  box-shadow: 0px 4px 20px 0px #0000001a;
}
// canvas classes

// utility classes
.text-divider {
  position: relative;
  text-align: center;
}
.text-divider:after {
  content: "";
  border-bottom: 1px solid #d0d5dd;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}
.text-divider span {
  padding: 0 8px;
  position: relative;
  z-index: 2;
}

.loader {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.loader:after {
  content: " ";
  display: block;
  width: 25px;
  height: 25px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loader 1.2s linear infinite;
}

.modal-loader .loader:after {
  content: " ";
  display: block;
  width: 25px;
  height: 25px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid #000000;
  border-color: #000 transparent #000 transparent;
  animation: loader 1.2s linear infinite;
}

.download-dropdown-option {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 6px;
  display: block;
  top: 68px;
  left: 0;
  right: 0;
  border: 0.5px solid #eaeaea;
  background: #fff;
  transition: all 0.5s;
  z-index: 999;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 10px 17px 48px -16px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 10px 17px 48px -16px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 17px 48px -16px rgba(0, 0, 0, 0.2);
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bounce {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

// utility classes

// Specific Canvas Classes
.App {
  font-family: sans-serif;
  text-align: center;
}

/** Reset margins for items to mimic the styles of the quill editor better */
p,
ul,
ol,
dl,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
form,
table {
  margin: 0;
}

.canvas-container {
  width: 100%;
  height: 80vh;
  position: relative;
  overflow: hidden;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
}

.toolbar-container {
  display: flex;
  width: 100%;
  background: white;
  margin-bottom: 10px;
  user-select: none;
}

.toolbar-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  margin-right: 5px;
  border-radius: 3px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.toolbar-item:hover {
  background: rgb(228, 228, 228);
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
}

.item-container {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

[contenteditable] {
  outline: none !important;
}

// react-colorful
.react-colorful {
  width: 100% !important;
}

.showHandles .handle {
  display: block;
}

.handle {
  display: none;
  width: 10px !important;
  height: 10px !important;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #ccc;
  position: absolute;
  box-shadow:
    0 0 5px 1px rgb(57 76 96 / 15%),
    0 0 0 1px rgb(53 71 90 / 20%);
}

.long-handle {
  height: 15px !important;
  width: 5px !important;
}

.long-handle-horizontal {
  height: 5px !important;
  width: 15px !important;
}

.right-handle.bottom-handle {
  transform: translate(-4px, -4px);
}

.right-handle.top-handle {
  transform: translate(-4px, 4px);
}

.left-handle.bottom-handle {
  transform: translate(4px, -4px);
}

.left-handle.top-handle {
  transform: translate(4px, 4px);
}

.long-handle-horizontal.bottom-handle,
.long-handle-horizontal.top-handle {
  left: 50% !important;
  transform: translateX(-8px);
}

.long-handle.left-handle,
.long-handle.right-handle {
  top: 50% !important;
  transform: translateY(-8px);
}

.image-upload-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#imageFile {
  display: none;
}

/* Thin scrollbar for webkit browsers */
.thin-scrollbar::-webkit-scrollbar {
  width: 4px; 
  height: 4px; 
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.284); 
  border-radius: 8px; 
}

.thin-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); 
}

// shadcn-ui specific tailwind config
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
