/* The Modal (background) */
.modal-el {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  /* Modal Content/Box */
  .modal-content {
    margin: 1% auto; /* 1% from the top and centered */
    width: 80%; /* Could be more or less, depending on screen size */
  }
}

.rename-project-modal {
  input {
    width: 100%;
  }

  button {
    display: flex;
    align-items: center;
  }

  .loader {
    width: auto !important;
    height: auto !important;
    display: flex;
    align-items: center;

    &:after {
      width: 18px;
      height: 18px;
    }
  }
}

.profile-setup-modal {
  button {
    width: 100%;
  }
}

// waitlist success modal
.waitlist-sucess-modal {
  background-image: url("../../assets/png/success-waitlist.webp");

  background-size: 8rem;
  background-position: top right;

  @media screen and (min-width: 800px) {
    background-size: 20rem;
    background-position: right;
  }

  background-repeat: no-repeat;
}
// waitlist success modal
